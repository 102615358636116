<template>
  <div class="pa-5">
    <v-card class="mx-auto">
      <v-card-title>
        <h2 v-t="'Categories.ProductCategoriesManagement'"></h2>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$router.push('Categories/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('Categories.AddCategory') }}
        </v-btn>
      </v-card-title>
      <div class="table-container">
        <div class="header-row">
          <div class="little-space-teehee"></div>
          <div class="table-element drag"></div>
          <div v-t="'General.PublishingStatus'" class="table-element"></div>
          <div v-t="'Categories.CategoryName'" class="table-element"></div>
          <div v-t="'General.Actions'" class="table-element"></div>
        </div>
        <vuetify-draggable-treeview v-model="categories" @input="updateIndexes" style="display: flex; flex-flow: column nowrap; justify-content:space-evenly;">
          <template v-slot:label="{ item }">
            <div class="body-row">
              <div class="table-element drag">
                <div class="table-element-center">
                  <v-icon style="padding: 10px; cursor: move">mdi-cursor-move</v-icon>
                </div>
              </div>
              <div class="table-element">
                <div class="table-element-center">
                  <v-switch
                  @change="togglePublished(item)"
                    v-model="item.published"
                  ></v-switch>
                </div>
              </div>
              <div class="table-element">
                  {{ item.name }}
              </div>
              <div class="table-element">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="secondary" icon dark @click="$router.push('Categories/' + item.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'General.Edit'"></span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" icon @click="deleteCategory(item)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'General.Delete'"></span>
                </v-tooltip>
              </div>
            </div>
          </template>
        </vuetify-draggable-treeview>
      </div>
    </v-card>
  </div>
</template>

<script>
import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'

export default {
  name: 'Categories',
  components: { VuetifyDraggableTreeview },
  data () {
    return {
      categories: [],
      products: []
    }
  },
  created () {
    this.getCategories()
  },
  methods: {
    initCategories () {
      this.categories.sort((a, b) => {
        return b.index < a.index ? 1 : -1
      })
    },
    async updateIndexes (categories) {
      return new Promise((resolve, reject) => {
        categories.forEach(async (category) => {
          if (category.index !== categories.indexOf(category)) {
            category.index = categories.indexOf(category)
            await this.updateCategory(category)
          }
          if (category.index === categories.length - 1) {
            resolve()
          }
        })
      })
    },
    async updateCategory (category) {
      await this.$store.dispatch('editCategory', category).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    deleteCategory (category) {
      this.$store.dispatch('getAllProducts').then(() => {
        var removable = true
        this.products = this.$store.getters['GET_PRODUCTS']()
        this.products.forEach((product) => {
          if (product.categoryId === category.id) {
            this.$emit('notify', {
              color: 'red',
              text: this.$t('Messages.ErrorDeleteCategory')
            })
            removable = false
          }
        })
        if (removable) {
          this.$store.dispatch('deleteCategory', category.id).then(async () => {
            const index = this.categories.indexOf(category)
            this.categories.splice(index, 1)
            await this.updateIndexes(this.categories)
            this.getCategories()
            this.$emit('notify', {
              color: 'green',
              text: this.$t('Messages.CategoryDeleted')
            })
          }).catch((err) => {
            this.$emit('notify', {
              color: 'red',
              text: this.$t('Messages.' + err.response.data)
            })
          })
        }
      })
    },
    getCategories () {
      this.$store.dispatch('getAllCategories').then(() => {
        this.categories = this.$store.getters['GET_CATEGORIES']()
        this.initCategories()
      })
    },
    togglePublished (category) {
      this.$store.dispatch('editCategory', category).then(res => {
        this.getCategories()
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    }
  }
}
</script>

<style scoped>
.table-container {
  display: flex;
  flex-flow: column nowrap;
}

.header-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 48px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.header-row:hover,

.header-row .table-element {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}

.little-space-teehee {
  width: 24px;
}

.table-element {
  flex: 1;
  flex-basis: 0;
  min-width: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  white-space: normal;
}

.drag {
  flex-grow: 0.2;
}

.table-element-center {
  display: flex;
  justify-content: center;
}

.body-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
</style>
